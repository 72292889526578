import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const login = createAsyncThunk('login', async (data) => {
  try {
    const response = await AxiosInstance.post(`/user/login`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoading: false,
    loginData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loginData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default loginSlice.reducer;
