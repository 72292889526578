import React, { useState } from 'react';
import './filesToolSection.scss';
import {
  arrow_right,
  doc_tool_icon,
  image_tool_icon,
  vector_tool_icon,
  video_tool_icon
} from '../../../../assets/images/home';
import ConvertDropbox from '../ConvertDropbox/ConvertDropbox';

const FilesToolSectionComponent = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [docuument, setDocument] = useState(false);
  const [vector, setVector] = useState(false);
  const [video, setVideo] = useState(false);

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
    closeOtherDropdowns(1);
  };
  const handleShowDocument = () => {
    setDocument(!docuument);
    closeOtherDropdowns(2);
  };
  const handleShowVector = () => {
    setVector(!vector);
    closeOtherDropdowns(3);
  };
  const handleShowVideo = () => {
    setVideo(!video);
    closeOtherDropdowns(4);
  };

  const closeOtherDropdowns = (currentDropdown) => {
    switch (currentDropdown) {
      case 1:
        setDocument(false);
        setVector(false);
        setVideo(false);
        break;
      case 2:
        setShowDropdown(false);
        setVector(false);
        setVideo(false);
        break;
      case 3:
        setShowDropdown(false);
        setDocument(false);
        setVideo(false);
        break;
      case 4:
        setShowDropdown(false);
        setDocument(false);
        setVector(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* <!------------------- Tool Section Start -------------> */}

      <section className='tool-section blue-design-pattern pt-100' id='conversion-types'>
        <div className='main_container'>
          <div className='common-title'>
            <span>- Tools</span>
            <h2>
              Convert all types <br />
              of files
            </h2>
          </div>

          <div className='tool-inner-slider'>
            <div className='toll-box-inner toll-box_wrap1'>
              <div className='tool-box'>
                <div
                  className='box-upper'
                  style={{
                    '--toolBodyClr': '#6F56EC',
                    '--elementClr': '#9D8CF2'
                  }}
                >
                  <div className='box-head'>
                    <div className='icon-box'>
                      <img src={image_tool_icon} alt='' />
                    </div>
                    <div className='number-tool'>13+ tools</div>
                  </div>
                  <div className='box-body'>
                    <div className='box-content'>
                      <div>
                        <h3 className='tool-name'>Image Tools</h3>
                        <p>Solve Your Img Problems</p>
                      </div>
                      <img src={arrow_right} alt='' onClick={handleShowDropdown} />
                    </div>
                    {showDropdown ? (
                      <ul className='file-types active'>
                        <li>
                          <a href='javascript:void(0)'>3FR</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ARW</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>AVIF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>BMP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CR2</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CR3</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CRW</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DCR</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DNG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>EPS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ERF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>GIF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>HEIC</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>HEIF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ICNS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ICO</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>JFIF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>JPEG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>JPG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MOS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MRW</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>NEF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ODD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ODG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ORF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PEF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PNG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PPM</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PSD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RAF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RAW</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RW2</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>TIF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>TIFF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WEBP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>X3F</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>XCF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>XPS</a>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </div>
                <div className='box-footer' style={{ '--footerClr': '#EFEDFD' }}>
                  <p>
                    <span>Featured Tool :</span>
                    <a href='javascript:void(0)'>Image Convert</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='toll-box-inner toll-box_wrap2'>
              <div className='tool-box'>
                <div
                  className='box-upper'
                  style={{
                    '--toolBodyClr': '#F66213',
                    '--elementClr': '#F8935F'
                  }}
                >
                  <div className='box-head'>
                    <div className='icon-box'>
                      <img src={doc_tool_icon} alt='' />
                    </div>
                    <div className='number-tool'>10+ tools</div>
                  </div>
                  <div className='box-body'>
                    <div className='box-content'>
                      <div>
                        <h3 className='tool-name'>Document Tools</h3>
                        <p>Solve Your Doc. Problems</p>
                      </div>
                      <img src={arrow_right} alt='' onClick={handleShowDocument} />
                    </div>
                    {docuument ? (
                      <ul className='file-types active'>
                        <li>
                          <a href='javascript:void(0)'>ABW</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DJVU</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DOC</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DOCM</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DOCX</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DOT</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DOTX</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>HTML</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>HWP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>LWP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ODT</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PAGES</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>PDF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RST</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RTF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>TEX</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>TXT</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WPD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WPS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>ZABW</a>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </div>
                <div className='box-footer' style={{ '--footerClr': '#FEF2EB' }}>
                  <p>
                    <span>Featured Tool :</span>
                    <a href='javascript:void(0)'>Marge Document</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='toll-box-inner toll-box_wrap3'>
              <div className='tool-box'>
                <div
                  className='box-upper'
                  style={{
                    '--toolBodyClr': '#1C67CA',
                    '--elementClr': '#6598DB'
                  }}
                >
                  <div className='box-head'>
                    <div className='icon-box'>
                      <img src={vector_tool_icon} alt='' />
                    </div>
                    <div className='number-tool'>8+ tools</div>
                  </div>
                  <div className='box-body'>
                    <div className='box-content'>
                      <div>
                        <h3 className='tool-name'>Vector Tools</h3>
                        <p>Solve Your Doc. Problems</p>
                      </div>
                      <img src={arrow_right} alt='' onClick={handleShowVector} />
                    </div>
                    {vector ? (
                      <ul className='file-types active'>
                        <li>
                          <a href='javascript:void(0)'>AI</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CDR</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CGM</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>EMF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>SK</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>SK1</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>SVG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>SVGZ</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>VSD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WMF</a>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </div>
                <div className='box-footer' style={{ '--footerClr': '#EDF4FD' }}>
                  <p>
                    <span>Featured Tool :</span>
                    <a href='javascript:void(0)'>Vector Document</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='toll-box-inner toll-box_wrap4'>
              <div className='tool-box'>
                <div
                  className='box-upper'
                  style={{
                    '--toolBodyClr': '#247881',
                    '--elementClr': '#6AA3AA'
                  }}
                >
                  <div className='box-head'>
                    <div className='icon-box'>
                      <img src={video_tool_icon} alt='' />
                    </div>
                    <div className='number-tool'>12+ tools</div>
                  </div>
                  <div className='box-body'>
                    <div className='box-content'>
                      <div>
                        <h3 className='tool-name'>Video Tools</h3>
                        <p>Solve Your Doc. Problems</p>
                      </div>
                      <img src={arrow_right} alt='' onClick={handleShowVideo} />
                    </div>
                    {video ? (
                      <ul className='file-types active'>
                        <li>
                          <a href='javascript:void(0)'>3G2</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>3GP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>3GPP</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>AVI</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>CAVS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DV</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>DVR</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>FLV</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>M2TS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>M4V</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MKV</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MOD</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MOV</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MP4</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MPEG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MPG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MTS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>MXF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>OGG</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RM</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>RMVB</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>SWF</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>TS</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>VOB</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WEBM</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WMV</a>
                        </li>
                        <li>
                          <a href='javascript:void(0)'>WTV</a>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </div>
                <div className='box-footer' style={{ '--footerClr': '#EFF9FB' }}>
                  <p>
                    <span>Featured Tool :</span>
                    <a href='javascript:void(0)'>Other Document</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="file-section slider tools-data-1">
        <Slider {...settings}>
          {toolsData_1.map((item, index) => (
            <div className="card-wrapper" key={index}>
              <BoxComponent
                data={item}
                index={index}
                menuVisible={menuVisible}
                handleMenu={handleMenu}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="file-section non-slider tools-data-1">
        {toolsData_1.map((item, index) => (
          <div className="card-wrapper" key={index}>
            <BoxComponent
              data={item}
              index={index}
              menuVisible={menuVisible}
              handleMenu={handleMenu}
            />
          </div>
        ))}
      </div> */}
        </div>
      </section>

      <ConvertDropbox />

      {/* <section className="about-file-converter-section">
        <div className="main_container">
          <div className="tool-about-box-wrap">
            <div className="tool-about-box">
              <div className="box-icon">
                <img src={file_icon} alt="" />
              </div>
              <div className="about-box-content">
                <h3>Convert All File Types</h3>
                <p>
                  Convert anything from videos and websites to documents and
                  pdfs online.
                </p>
                <a href="javascript:void(0)">
                  Learn More <img src={black_arrow_right} alt="" />
                </a>
              </div>
            </div>
            <div className="tool-about-box">
              <div className="box-icon">
                <img src={drop_circle_icon} alt="" />
              </div>
              <div className="about-box-content">
                <h3>Convert Files From Dropbox</h3>
                <p>
                  Convert your files from Dropbox, Google Drive, OneDrive and
                  Box.
                </p>
                <a href="javascript:void(0)">
                  Learn More <img src={black_arrow_right} alt="" />
                </a>
              </div>
            </div>
            <div className="tool-about-box">
              <div className="box-icon">
                <img src={file_encrypted_icon} alt="" />
              </div>
              <div className="about-box-content">
                <h3>Everything is Encrypted</h3>
                <p>
                  All files are stored encrypted with AES 256. Only you can
                  unlock them.
                </p>
                <a href="javascript:void(0)">
                  Learn More <img src={black_arrow_right} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!------------------- Tool Section End -------------> */}
    </>
  );
};

export default FilesToolSectionComponent;
