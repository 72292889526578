import React, { useEffect } from 'react';
import './account.scss';
import countryList from 'react-select-country-list';
const AccountComponent = ({ userDetails, handleChangeDetails, handleUpdateProfile, detailsErrors, authenticate }) => {
  const countryOptions = countryList().getData();
  let email = authenticate && authenticate.data.email;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
      <div className='main_root'>
        <div className='wpb_info_wrapper'>
          <div className='main_container'>
            <div className='mt-50 wpb_content_account account_bg_wrap'>
              <div className='account_title'>
                <h2>Account Information</h2>
              </div>
              <div className='account_info_inner'>
                <div className='wi-50 account_address'>
                  <strong>Email Address</strong>
                  <p>
                    <a>{email} </a>
                  </p>
                </div>
                <div className='wi-50 account_address'>
                  <strong>MEMBER SINCE</strong>
                  <p>
                    <a href='/en/my-account'>
                      <time className='' timestamp='2024-07-11T05:09:39.493Z'>
                        March 11, 2024
                      </time>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='wpb_content_billing account_bg_wrap'>
              <div className='account_title'>
                <h2>Billing Information</h2>
              </div>
              <div className='form_group_warp'>
                <form className='ant-form ant-form-horizontal css-mzwlov'>
                  <div className='account-container'>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='first-name'>
                        First Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='first-name'
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.firstName}
                      />
                      {detailsErrors.firstName && <p className='errors'>{detailsErrors.firstName}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='last-name'>
                        Last Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='last-name'
                        type='text'
                        inputmode='text'
                        name='lastName'
                        placeholder='Last Name'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.lastName}
                      />
                      {detailsErrors.lastName && <p className='errors'>{detailsErrors.lastName}</p>}
                    </div>
                    <div className='field-account address'>
                      <label className='lbl_wpb_wrapper' for='street-address'>
                        Street Address <span className='required-field'>*</span>
                      </label>
                      <input
                        id='street-address'
                        type='text'
                        name='streetAddress'
                        inputmode='text'
                        placeholder='Los Angeles, 9 Stonehedge Dr S'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.streetAddress}
                      />
                      {detailsErrors.streetAddress && <p className='errors'>{detailsErrors.streetAddress}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='country'>
                        Country <span className='required-field'>*</span>{' '}
                      </label>
                      <select
                        class='ant-input-prefix'
                        name='country'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.country}
                      >
                        <option value=''>Select Your Country</option>
                        {countryOptions?.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {detailsErrors.country && <p className='errors'>{detailsErrors.country}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='city'>
                        City <span className='required-field'>*</span>{' '}
                      </label>
                      <input
                        id='city'
                        type='text'
                        name='city'
                        inputmode='text'
                        placeholder='Los Angeles'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.city}
                      />
                      {detailsErrors.city && <p className='errors'>{detailsErrors.city}</p>}
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='postal-code'>
                        Postal Code
                        <span className='required-field'>*</span>
                      </label>
                      <input
                        id='postal-code'
                        name='postalCode'
                        inputmode='text'
                        placeholder='90077'
                        class='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        type='text'
                        onChange={(e) => handleChangeDetails(e)}
                        value={userDetails.postalCode}
                      />
                      {detailsErrors.postalCode && <p className='errors'>{detailsErrors.postalCode}</p>}
                    </div>
                  </div>
                  <button class='payment__account' type='button' onClick={handleUpdateProfile}>
                    Save
                  </button>
                </form>
              </div>
            </div>
            <div className='wpb_content_subs account_bg_wrap'>
              <div className='account_title'>
                <h2>Subscriptions</h2>
              </div>
              <div className='subscriptions_info_wrap'>
                <span>Phone Tracking</span>
                <button>Cancel Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountComponent;
