import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import LoginModal from './loginModal';
import { login } from '../../../redux/slice/loginSlice';
import Notify from '../../common/notify';
import { validEmail } from '../../../utils/commonUtils';
import strings from '../../../utils/strings.json';

const Login = ({ callLoginData }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [error, setErrors] = useState(null);
  const [emailError, setEmailErrors] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [lang] = useState(cookies.get('lang') || 'en');

  const validateEmail = (email) => {
    const isValid = validEmail.test(email);
    setEmailErrors(!isValid && strings.PLEASE_ENTER_VALID_EMAIL);
    return isValid;
  };

  const handleValuesChange = () => {
    setEmailErrors(null);
    setErrors(null);
  };

  const onClickOfContinue = async (values) => {
    if (!validateEmail(values.email)) {
      return;
    }
    if (!values.password) {
      setPasswordError(strings.PLEASE_ENTER_PASSWORD);
      return;
    }
    setLoading(true);
    const loginData = {
      email: values.email.trim().toLowerCase(),
      password: values.password,
      provider: 'BASIC'
    };
    const result = await callLoginData(loginData);
    if (result?.type === 'login/fulfilled') {
      const { token, email, role } = result.payload.data;
      cookies.set('token', token);
      cookies.set('email', email);
      cookies.set('role', role);
      Notify('success', 'Successfully Login', '');
      navigate(`/${lang}/dashboard`);
    } else {
      setErrors(result?.error?.message || 'Login failed');
    }
    setLoading(false);
  };

  return (
    <LoginModal
      onClickOfContinue={onClickOfContinue}
      handleValuesChange={handleValuesChange}
      loginError={error}
      emailError={emailError}
      setEmailErrors={setEmailErrors}
      passwordError={passwordError}
      setPasswordError={setPasswordError}
      strings={strings}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state) => ({
  loginData: state.loginData
});

const mapDispatchToProps = (dispatch) => ({
  callLoginData: (data) => dispatch(login(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
