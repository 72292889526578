import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const paymentIntent = createAsyncThunk('paymentIntent', async (data) => {
  try {
    const response = await AxiosInstance.post(`/subscription/create-payment-intent`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const paymentIntentSlice = createSlice({
  name: 'paymentIntent',
  initialState: {
    isLoading: false,
    paymentIntentData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentIntent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paymentIntent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentIntentData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(paymentIntent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default paymentIntentSlice.reducer;
