import {
  all_Icon,
  archive,
  box_icon,
  cad,
  documents,
  drawing,
  drive_icon,
  drop_box_image,
  ebook,
  font,
  image,
  link_icon,
  others,
  presantation,
  spreadsheet,
  vector,
  video
} from '../assets/images/dashboard';
import { doc_tool_icon, image_tool_icon, vector_tool_icon, video_tool_icon } from '../assets/images/home';

export const languages = [
  { code: 'cz', name: 'Cesky', alt: 'Cesky', flagCode: 'cz' },
  { code: 'de', name: 'Deutsch', alt: 'Deutsch', flagCode: 'de' },
  { code: 'en', name: 'English', alt: 'ENGLISH', flagCode: 'gb' },
  { code: 'es', name: 'Español', alt: 'Español', flagCode: 'es' },
  {
    code: 'gr',
    name: 'Eλληνικός',

    alt: 'Eλληνικός',
    flagCode: 'gr'
  },
  { code: 'fr', name: 'French', alt: 'FRENCH', flagCode: 'fr' },
  { code: 'hu', name: 'Magyar', alt: 'Magyar', flagCode: 'hu' },
  {
    code: 'fi',
    name: 'Suomalainen',

    alt: 'Suomalainen',
    flagCode: 'fi'
  },
  {
    code: 'ice',
    name: 'Islenskur',

    alt: 'islenskur',
    flagCode: 'is'
  },
  {
    code: 'et',
    name: 'Eesti keel',

    alt: 'Eesti keel',
    flagCode: 'et'
  },
  { code: 'hi', name: 'हिंदी', alt: 'Hindi', flagCode: 'in' },
  {
    code: 'zhh',
    name: 'Cantonese',

    alt: 'Cantonese',
    flagCode: 'cn'
  },
  { code: 'th', name: 'Thai', alt: 'Thai', flagCode: 'th' },
  { code: 'bn', name: 'Bengali', alt: 'Bengali', flagCode: 'bn' },
  { code: 'ms', name: 'Malay', alt: 'Malay', flagCode: 'ms' },
  { code: 'kr', name: '한국어', alt: '한국어', flagCode: 'kr' },
  {
    code: 'hrv',
    name: 'Hrvatski',

    alt: 'Hrvatski',
    flagCode: 'hr'
  },
  {
    code: 'zh-CN',
    name: 'Chinese',

    alt: 'Chinese',
    flagCode: 'cn'
  },
  { code: 'ga', name: 'Gaeilge', alt: 'Gaeilge', flagCode: 'ga' },
  {
    code: 'id',
    name: 'Indonesian',

    alt: 'Indonesian',
    flagCode: 'id'
  },
  {
    code: 'ja',
    name: 'Japanese',

    alt: 'Japanese',
    flagCode: 'jp'
  },
  { code: 'sv', name: 'Svenska', alt: 'Svenska', flagCode: 'sv' },
  {
    code: 'it',
    name: 'Italiano',

    alt: 'Italiano',
    flagCode: 'it'
  },
  {
    code: 'bg',
    name: 'Български',

    alt: 'Български',
    flagCode: 'bg'
  },
  { code: 'sr', name: 'Serbian', alt: 'Serbian', flagCode: 'sr' },
  {
    code: 'uk',
    name: 'Ukrainian',

    alt: 'Ukrainian',
    flagCode: 'ua'
  },
  {
    code: 'heb',
    name: 'Hebrew',

    alt: 'Hebrew',
    flagCode: 'il'
  },
  { code: 'sk', name: 'Slovak', alt: 'Slovak', flagCode: 'sk' },
  { code: 'da', name: 'Danish', alt: 'Danish', flagCode: 'dk' },
  { code: 'ac', name: 'Arabic', alt: 'Arabic', flagCode: 'ae' },
  {
    code: 'nl',
    name: 'Nederlands',

    alt: 'Nederlands',
    flagCode: 'nl'
  },
  { code: 'no', name: 'Norsk', alt: 'Norsk', flagCode: 'no' },
  { code: 'pl', name: 'Polski', alt: 'Polski', flagCode: 'pl' },
  {
    code: 'zh',
    name: 'Mandarin',

    alt: 'Mandarin',
    flagCode: 'cn'
  },
  {
    code: 'pt',
    name: 'Português',

    alt: 'Português',
    flagCode: 'pt'
  },
  {
    code: 'ro',
    name: 'Romanesc',

    alt: 'Romanesc',
    flagCode: 'ro'
  },
  {
    code: 'sl',
    name: 'Slovenscina',

    alt: 'Slovenscina',
    flagCode: 'sl'
  },
  { code: 'tr', name: 'Turkish', alt: 'turkish', flagCode: 'tr' },
  {
    code: 'pt-br',
    name: 'Portuguese (BR)',

    alt: 'Portuguese (BR)',
    flagCode: 'pt'
  }
];

export const fields = [
  {
    id: 1,
    field: 'All',
    image: all_Icon
  },
  {
    id: 2,
    field: 'vector',
    image: vector
  },
  {
    id: 3,
    field: 'Image',
    image: image
  },
  {
    id: 4,
    field: 'Font',
    image: font
  },
  {
    id: 5,
    field: 'Archive',
    image: archive
  },
  {
    id: 6,
    field: 'Cad',
    image: cad
  },
  {
    id: 7,
    field: 'Document',
    image: documents
  },
  {
    id: 8,
    field: 'Ebook',
    image: ebook
  },
  {
    id: 9,
    field: 'Other',
    image: others
  },
  {
    id: 10,
    field: 'Presentation',
    image: presantation
  },
  {
    id: 11,
    field: 'Spreadsheet',
    image: spreadsheet
  },
  {
    id: 12,
    field: 'Drawing',
    image: drawing
  },
  {
    id: 13,
    field: 'Video',
    image: video
  }
];

export const toolsData_1 = [
  {
    toolBodyClr: '#6F56EC',
    elementClr: '#9D8CF2',
    icon: image_tool_icon,
    numberOfTools: '13+ tools',
    toolName: 'Image Tools',
    description: 'Solve Your Img Problems',
    fileTypes: [
      '3FR',
      'ARW',
      'AVIF',
      'BMP',
      'CR2',
      'CR3',
      'CRW',
      'DCR',
      'DNG',
      'EPS',
      'ERF',
      'GIF',
      'HEIC',
      'HEIF',
      'ICNS',
      'ICO',
      'JFIF',
      'JPEG',
      'JPG',
      'MOS',
      'MRW',
      'NEF',
      'ODD',
      'ODG',
      'ORF',
      'PEF',
      'PNG',
      'PPM',
      'PS',
      'PSD',
      'RAF',
      'RAW',
      'RW2',
      'TIF',
      'TIFF',
      'WEBP',
      'X3F',
      'XCF',
      'XPS'
    ],
    featuredTool: 'Image Convert'
  },
  {
    toolBodyClr: '#F66213',
    elementClr: '#F8935F',
    icon: doc_tool_icon,
    numberOfTools: '10+ tools',
    toolName: 'Document Tools',
    description: 'Solve Your Doc. Problems',
    fileTypes: [
      'ABW',
      'DJVU',
      'DOC',
      'DOCM',
      'DOCX',
      'DOT',
      'DOTX',
      'HTML',
      'HWP',
      'LWP',
      'MD',
      'ODT',
      'PAGES',
      'PDF',
      'RST',
      'RTF',
      'TEX',
      'TXT',
      'WPD',
      'WPS',
      'ZABW'
    ],
    featuredTool: 'Merge Document'
  },
  {
    toolBodyClr: '#1C67CA',
    elementClr: '#6598DB',
    icon: vector_tool_icon,
    numberOfTools: '8+ tools',
    toolName: 'Vector Tools',
    description: 'Solve Your Vector Problems',
    fileTypes: ['AI', 'CDR', 'CGM', 'EMF', 'SK', 'SK1', 'SVG', 'SVGZ', 'VSD', 'WMF'],
    featuredTool: 'Vector Document'
  },
  {
    toolBodyClr: '#247881',
    elementClr: '#6AA3AA',
    icon: video_tool_icon,
    numberOfTools: '12+ tools',
    toolName: 'Video Tools',
    description: 'Solve Your Video Problems',
    fileTypes: [
      '3G2',
      '3GP',
      '3GPP',
      'AVI',
      'CAVS',
      'DV',
      'DVR',
      'FLV',
      'M2TS',
      'M4V',
      'MKV',
      'MOD',
      'MOV',
      'MP4',
      'MPEG',
      'MPG',
      'MTS',
      'MXF',
      'OGG',
      'RM',
      'RMVB',
      'SWF',
      'TS',
      'VOB',
      'WEBM',
      'WMV',
      'WTV'
    ],
    featuredTool: 'Video Converter'
  }
];

export const dashboarditems = [
  {
    id: 1,
    image: box_icon,
    name: 'Box'
  },
  {
    id: 1,
    image: drop_box_image,
    name: 'Dropbox'
  },
  {
    id: 1,
    image: link_icon,
    name: 'Get From URL'
  },
  {
    id: 1,
    image: drive_icon,
    name: 'Google Drive'
  }
];

export const country = 'Australia';
export const currency = 'eur';
export const validEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
export const paymentElementOptions = {
  layout: 'tabs',
  terms: {
    card: 'never',
    applePay: 'never',
    googlePay: 'never',
    cashapp: 'never',
    auBecsDebit: 'never',
    bancontact: 'never',
    ideal: 'never',
    paypal: 'never',
    sepaDebit: 'never',
    sofort: 'never',
    usBankAccount: 'never'
  }
};

export const appearance = {
  theme: 'stripe'
};

export const stripeOptions = {
  All: [
    'EMF',
    'WMF',
    'BPM',
    'EPS',
    'GIF',
    'ICO',
    'JPG',
    'ODD',
    'PNG',
    'PS',
    'PSD',
    'TIFF',
    'AAC',
    'AC3',
    'AIF',
    'AIFC',
    'AIFF',
    'AIMR',
    'DWG',
    'DXF',
    'ABW',
    'DJVU',
    'DOC',
    'DOCM',
    'HTML',
    'HWP',
    'AZM',
    'AZW3',
    'CBR',
    'LIT',
    'OGV',
    'OPUS',
    'DPS',
    'KEY',
    'AI',
    'CDR'
  ],
  vector: ['2.1', '2.2', '2.3', '2.4', '2.1', '2.2', '2.3', '2.4', '2.5'],
  Image: ['3.1', '4.3', '3.2'],
  Font: ['EMF'],
  Archive: ['BPM'],
  Cad: ['1.1'],
  Document: ['AZM'],
  Ebook: ['TIFF'],
  Other: ['ICO'],
  Presentation: ['ODD'],
  Spreadsheet: ['AIF'],
  Drawing: ['DXF'],
  Video: ['DOC']
};

export const validateField = (errors, fieldName, value, errorMessage) => {
  if (value.trim() === '') {
    errors[fieldName] = errorMessage;
  }
};

// With Token not accessible route
export const publicRoute = [
  'contact',
  'pricing',
  'unsubscribe',
  'login',
  'reset-password',
  'convert',
  'privacy',
  'terms',
  'cookie',
  'payment'
];
// Without Token not accessible route
export const privetRoute = ['dashboard', 'account'];
