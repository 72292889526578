import React, { useState } from 'react';
import './filesConvert.scss';
import { drive_icon, link_icon } from '../../../../assets/images/home';
import { pdf_png_icon, pdf_svg_icon } from '../../../../assets/images/dashboard';
import {
  box_icon,
  cancle_btn,
  Cancle_button,
  down_arrows,
  drop_box_image,
  naturevolcano,
  right_arrows,
  file_convert_icon,
  document_flow_icon
} from '../../../../assets/images/dashboard';
import Search from 'antd/es/input/Search';
import { fields, stripeOptions } from '../../../../utils/commonUtils';
import { Progress, Spin } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import PaymentModal from '../../../modals/paymentModal/paymentModal';

const FileConvertComponent = (props) => {
  const {
    move,
    percent,
    setisOpen,
    isOpen,
    handleRegister,
    checkoutModalOpen,
    clientSecret,
    options,
    stripePromise,
    customer,
    setCheckoutModalOpen,
    isLoading
  } = props;
  return (
    <>
      <section className='banner-section'>
        <div className='main_container'>
          <div className='banner-content'>
            <h1>Files Convert</h1>
            <p>Convert archives, documents, images, videos, audio, websites and more online.</p>
          </div>
          {move ? (
            <div>
              <div className='drop-box-move-section'>
                <div className='drag-drop-inner'>
                  <div className='drag-drop-element'>
                    <div className='drop-box-move-inner'>
                      <div className='box-move-head-content'>
                        <div className='convert-box-content'>
                          <img src={pdf_png_icon} alt='Drop Box' />
                          <span>PNG</span>
                        </div>
                        <div className='convert-box-content'>
                          <img src={file_convert_icon} alt='Drop Box' />
                        </div>
                        <div className='convert-box-content'>
                          <img src={pdf_svg_icon} alt='Drop Box' />
                          <span>SVG</span>
                        </div>
                      </div>
                      <div className='drop-box-convert-icon'>
                        <img src={document_flow_icon} alt='Drop Box' />
                      </div>
                      <div className='drop-box-convert-content'>
                        <h3>Converting Files.... {percent}%</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div className='drop-box-progress-bar'>
                          <div className='progress-bar-inner'>
                            <Progress
                              id='progressBar'
                              strokeColor={{
                                from: '#0076CE',
                                to: '#0076CE'
                              }}
                              percent={percent}
                              showInfo={false}
                              status='active'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spin spinning={isLoading}>
              <div className='drag-drop-inner'>
                <div className='drag-drop-element'>
                  <form>
                    <input type='file' id='drag_drop_file' />
                    <label for='drag_drop_file'>Drag & Drop files here to upload</label>
                  </form>
                </div>
                <div className='banner-device-element'>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={drop_box_image} alt='Drop Box' />
                      <span>Dropbox</span>
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={link_icon} alt='Link' />
                      <span>Get From URL</span>
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={drive_icon} alt='Google Drive' />
                      <span>Google Drive</span>
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={box_icon} alt='Box' />
                      <span>Box</span>
                    </div>
                  </div>
                </div>
                <div className='file-convert-list-main'>
                  <div className='your-file single_line'>
                    <span>Your Files</span>
                  </div>
                  <div className='convert-formate-main'>
                    <div className='file-converting-main'>
                      <div className='file-convert-img'>
                        <img src={naturevolcano} alt='' />
                      </div>
                      <div className='file-list-inner'>
                        <p>naturevolcano</p>
                        <span>58.46 KB</span>
                      </div>
                    </div>
                    <div className='convert-to-main'>
                      <div className='convert-formate'>
                        <span>png</span>
                        <div className='convert-img'>
                          <img src={right_arrows} alt='' />
                        </div>
                        <div className='convert-option'>
                          <div onClick={() => setisOpen(true)}>
                            Convert to
                            <img src={down_arrows} alt='' />
                          </div>
                        </div>
                      </div>
                      <div className='convert-close' onClick={() => setisOpen(false)}>
                        <img src={Cancle_button} alt='' />
                      </div>
                    </div>
                  </div>
                  {isOpen && <ConverterDropdown setisOpen={setisOpen} />}
                  <div className='convert-btn'>
                    <button onClick={() => handleRegister()} disabled={isLoading}>
                      Convert Files
                    </button>
                  </div>
                </div>
                <div></div>
              </div>
            </Spin>
          )}
        </div>
      </section>

      {checkoutModalOpen && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModal
            clientSecret={clientSecret}
            checkoutModalOpen={checkoutModalOpen}
            setCheckoutModalOpen={setCheckoutModalOpen}
            customer={customer}
          />
        </Elements>
      )}
    </>
  );
};

export default FileConvertComponent;

const ConverterDropdown = (props) => {
  const { setisOpen } = props;

  const [selectedField, setSelectedField] = useState('All');

  const handleFieldChange = (field) => {
    setSelectedField(field);
  };

  return (
    <div className='custom-select-popup'>
      <div className='popup'>
        <div className='close-btn'>
          <img src={cancle_btn} alt='' onClick={() => setisOpen(false)} />
        </div>
        <div className='popup-row'>
          <Search placeholder='Search...' style={{ width: '100%' }} />
        </div>
        <div className='popup-row columns'>
          <div className='column first-column'>
            <div className='fields-list'>
              {fields.map((field, index) => (
                <div
                  key={index}
                  className={`field-item ${selectedField === field.field ? 'selected' : ''}`}
                  onClick={() => handleFieldChange(field.field)}
                >
                  <img src={field.image} alt='' />
                  <span>{field.field}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='column second-column'>
            {selectedField && (
              <ul>
                {stripeOptions[selectedField].map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
