import React, { useEffect, useState } from 'react';
import FileConvertComponent from './filesConvert';
import { loadStripe } from '@stripe/stripe-js';
import { stripeLoadScript } from '../../../../environment';
import Notify from '../../../common/notify';
import { appearance, currency } from '../../../../utils/commonUtils';
import { paymentIntent } from '../../../../redux/slice/paymentIntentSlice';
import { connect } from 'react-redux';

const stripePromise = loadStripe(stripeLoadScript);

const FileConvert = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [percent, setPercent] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState('');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

  const { callpaymentIntent } = props;
  const options = {
    clientSecret,
    appearance,
    locale: 'en'
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 100);

    return () => {
      clearInterval(interval);
      setMove(false);
    };
  }, []);

  useEffect(() => {
    if (percent === 100) {
      setMove(false);
    }
  }, [percent]);

  const handleRegister = async () => {
    setLoading(true);
    const intentdata = {
      amount: 50,
      currency: currency
    };

    const paymentResult = await callpaymentIntent(intentdata);
    const { type, payload, error } = paymentResult || {};
    if (type === 'paymentIntent/fulfilled') {
      setCustomer(payload.data.customer);
      setClientSecret(payload.data.client_secret);
      setCheckoutModalOpen(true);
    } else {
      Notify('error', error.message, '');
    }
    setLoading(false);
  };

  return (
    <FileConvertComponent
      move={move}
      setLoading={setLoading}
      isLoading={isLoading}
      percent={percent}
      setisOpen={setisOpen}
      isOpen={isOpen}
      handleRegister={handleRegister}
      checkoutModalOpen={checkoutModalOpen}
      clientSecret={clientSecret}
      options={options}
      stripePromise={stripePromise}
      customer={customer}
      setCheckoutModalOpen={setCheckoutModalOpen}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callpaymentIntent: (data) => dispatch(paymentIntent(data))
  };
};

export default connect(null, mapDispatchToProps)(FileConvert);
