import React, { useEffect } from 'react';
import './cookie.scss';

const CookieComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className='cookiesPolicy-main-section'>
      <div className='back-grad-title'>
        <div className='pricing-main-title'>Cookies Policy</div>
      </div>
      <div className='main_container'>
        <div className='wrap'>
          <div className='dashboard-box'>
            <div className='card mt-4'>
              <div class='docs-section'>
                <h5>
                  <span>Summary</span>
                </h5>
                <p>
                  <span>
                    Cookies used: <b>Own and Third-Party</b>
                  </span>
                </p>
                <p>
                  <span>
                    Are they used for statistical purposes? <b>Yes</b>
                  </span>
                </p>
                <p>
                  <span>
                    Are they used for advertising? <b>No</b>
                  </span>
                </p>
                <p>
                  <span>
                    Third-party services that install cookies through this website:{' '}
                    <b>Google Analytics and Google Maps</b>
                  </span>
                </p>
              </div>
              <div class='docs-section'>
                <h5>
                  <span>How to configure and disable cookies</span>
                </h5>
                <p class='mb-1'>
                  <span>
                    You can allow, block or delete cookies installed on your device from your web browser. If you do not
                    allow cookies to be installed on your device, your browsing experience may be worse. Below are
                    instructions on how to configure cookies on each browser:
                  </span>
                </p>
                <ul class='inline'>
                  <li>
                    <a href='https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq&amp;sa=D&amp;ust=1491479807280000&amp;usg=AFQjCNExWkpVolVZ5WmGkhMrRq4WoOOmZg'>
                      Edge
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl=es&amp;sa=D&amp;ust=1491479807282000&amp;usg=AFQjCNHVfPwTJqjehsKZwLpTTdx3t6PtEA'>
                      Google Chrome
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=https://support.google.com/chrome/answer/2392971?hl=es&amp;sa=D&amp;ust=1491479807283000&amp;usg=AFQjCNFT5Xzp_9_45YIQ-0GR0UuV8xLILQ'>
                      Google Android
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies&amp;sa=D&amp;ust=1491479807284000&amp;usg=AFQjCNFT8rPLRd8ZdCrmxDNkLAv8RhYRTg'>
                      Internet Explorer 7 y 8
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9&amp;sa=D&amp;ust=1491479807285000&amp;usg=AFQjCNF9oECm0dg_3XyL9IyTePwvZMc--w'>
                      Internet Explorer 9
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10&amp;sa=D&amp;ust=1491479807286000&amp;usg=AFQjCNG_be2prt0SGIbLHbLYlTvBKuXLog'>
                      Internet Explorer 10
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11&amp;sa=D&amp;ust=1491479807287000&amp;usg=AFQjCNH0Nga8-Cr9s6KJKl0PtnBZRBRprw'>
                      Internet Explorer 11
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we&amp;sa=D&amp;ust=1491479807289000&amp;usg=AFQjCNFXBrFz_ZqVfoUe64PmDCvaPiEzyA'>
                      Mozilla Firefox
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=http://help.opera.com/Linux/10.60/es-ES/cookies.html&amp;sa=D&amp;ust=1491479807290000&amp;usg=AFQjCNHN2JtRlZ81BjxHnhTCH16dsgGeIQ'>
                      Opera
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=https://support.apple.com/kb/PH19214?viewlocale=es_ES&amp;locale=es_ES&amp;sa=D&amp;ust=1491479807292000&amp;usg=AFQjCNFrGYaCxRmF4fWF9sZdkf7q1TQECQ'>
                      Safari
                    </a>
                  </li>
                  <li>
                    <a href='https://www.google.com/url?q=https://support.apple.com/es-es/HT201265&amp;sa=D&amp;ust=1491479807293000&amp;usg=AFQjCNF4ONSrS_BiUrCZXeJspPihfAhzvQ'>
                      Safari Mobile
                    </a>
                  </li>
                </ul>
              </div>
              <div class='docs-section'>
                <h5>
                  <span>1. Types and uses</span>
                </h5>
                <p>
                  <span>
                    The service uses its own and third-party cookies to improve browsing, to provide the service and to
                    obtain statistics on the website’s use.
                  </span>
                </p>
              </div>
              <div class='docs-section'>
                <h5>
                  <span>2. What is a cookie and what is it used for?</span>
                </h5>
                <p>
                  <span>
                    A cookie is a text file that is sent to the web browser of your computer, mobile phone or tablet and
                    is used to store and retrieve information on your browsing. For example, to remember your username
                    and password or your profile preferences.
                  </span>
                </p>
              </div>
              <div class='docs-section'>
                <h5>
                  <span>3. What types of cookies are there?</span>
                </h5>
                <p>
                  <span>According to who manages them:</span>
                </p>
                <p>
                  <span>
                    <u>Own cookies;</u> are sent to your browser from our computers or web domains.
                  </span>
                </p>
                <p>
                  <span>
                    <u>Third-party cookies;</u> are sent to your browser from a computer or web domain that we do not
                    directly manage, but a third party such as for example Google.
                  </span>
                </p>
                <p>
                  <span>According to the time they are active for, there are:</span>
                </p>
                <p>
                  <span>
                    <u>Session cookies;</u> remain in the cookie file of your browser until you leave the website, so
                    none are left on your device.
                  </span>
                </p>
                <p>
                  <span>
                    <u>Persistent cookies;</u> remain on your device and our website reads them every time you visit.
                    These cookies stop working after a specific date
                  </span>
                </p>
                <p>
                  <span>Finally, and according to their purpose, we have:</span>
                </p>
                <p>
                  <span>
                    <u>Technical cookies;</u> improve browsing and ensure the website works properly.
                  </span>
                </p>
                <p>
                  <span>
                    <u>Personalisation cookies;</u> enable access to the service with predefined characteristics based
                    on a series of criteria.
                  </span>
                </p>
                <p>
                  <span>
                    <u>Analysis cookies;</u> make it possible to measure and statistically analyse how the service
                    provided is used.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieComponent;
