import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';

import Notify from '../../common/notify';
import { validEmail } from '../../../utils/commonUtils';
import ResetModal from './resetModal';
import { resetPassword } from '../../../redux/slice/resetPasswordSlice';

const ResetPassword = ({ callResetPassword }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [error, setErrors] = useState(null);
  const [emailError, setEmailErrors] = useState(null);
  const [lang] = useState(cookies.get('lang') || 'en');
  const [isResetOpen, setIsResetOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const isValid = validEmail.test(email);
    setEmailErrors(isValid ? '' : 'Enter valid email');
    return isValid;
  };

  const handleValuesChange = () => {
    setEmailErrors(null);
    setErrors(null);
  };

  const handleResetPassword = async (values) => {
    if (!validateEmail(values.email)) {
      return;
    }

    const resetPasswordData = {
      email: values.email.trim().toLowerCase()
    };
    setLoading(true);
    const result = await callResetPassword(resetPasswordData);
    if (result?.type === 'resetPassword/fulfilled') {
      Notify('success', result?.payload?.message || 'Password Reset Successfully', '');
      setIsResetOpen(false);
    } else {
      setErrors(result?.error?.message || 'Reset Password Failed');
    }
    setLoading(false);
  };

  const handleClose = () => {
    setIsResetOpen(false);
    navigate(`/${lang}`);
  };

  return (
    <ResetModal
      handleResetPassword={handleResetPassword}
      handleValuesChange={handleValuesChange}
      resetPasswordError={error}
      emailError={emailError}
      isResetOpen={isResetOpen}
      setIsResetOpen={setIsResetOpen}
      handleClose={handleClose}
      form={form}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state) => ({
  resetPasswordData: state.resetPasswordData
});

const mapDispatchToProps = (dispatch) => ({
  callResetPassword: (data) => dispatch(resetPassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
