import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const unsubscribe = createAsyncThunk('unsubscribe', async (data) => {
  try {
    const response = await AxiosInstance.post(`/subscription/unsubscribe`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const unsubscribeSlice = createSlice({
  name: 'unsubscribe',
  initialState: {
    isLoading: false,
    unsubscribeData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(unsubscribe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unsubscribe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.unsubscribeData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(unsubscribe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default unsubscribeSlice.reducer;
