import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const updateProfile = createAsyncThunk('updateProfile', async (data) => {
  try {
    const response = await AxiosInstance.put(`/admin-user/update-profile`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const updateProfileSlice = createSlice({
  name: 'updateProfile',
  initialState: {
    isLoading: false,
    updateProfileData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateProfileData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default updateProfileSlice.reducer;
