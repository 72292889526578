import React from 'react';
// eslint-disable-next-line
import { fcWrokImg, perfect_editing_tool_img, rtlFileConvertImg } from '../../../../assets/images/home';
import './filesConvertInformative.scss';
const FilesConvertInformativeComponent = () => {
  return (
    <>
      {/* <!------------------- percect tool Section start -------------> */}
      <section className='Perfect-tool'>
        <div className='main_container'>
          <div className='Perfect-tool-info'>
            <img src={perfect_editing_tool_img} alt='' className='mobile-view-img' />
            <div className='right-content'>
              <img src={perfect_editing_tool_img} alt='' className='display-image-view' />
            </div>
            <div className='left-content'>
              <div className='common-title'>
                <span>- Files Convert</span>
                <h2>Files Convert - your perfect tool for creating and editing File!</h2>
                <p>
                  Files Convert makes editing a File document so easy, even if you have never edited a File before. Add
                  text, draw in the file, or even insert images. Our Files Convert is super-portable, so you can use it
                  on your computer or phone. Regardless of your operating system, you can edit and correct your
                  documents online in just a few clicks. We even offer a free 24 hours trial, so don't let it slip your
                  mind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------------------- perfect tool Section End -------------> */}

      {/* <!-------- Modern work section start  ---------> */}
      <section className='modern-work' id='how-it-works'>
        <div className='main_container'>
          <div className='modern-work-info'>
            <div className='left-content'>
              <div className='common-title'>
                <span>- How It Works</span>
                <h2>
                  Files Convert Made <br />
                  for Modern Work
                </h2>
                <p>Start by uploading your document to our Files Convert and online converter.</p>
              </div>
              <button className='upload-btn'>Upload Files</button>
            </div>
            <div className='right-content'>
              <img src={fcWrokImg} alt='' className='display-image-view display_img_block' />
            </div>
            <div className='right-content'>
              <img src={rtlFileConvertImg} alt='' className='display-image-view display_img_none' />
            </div>
          </div>
        </div>
      </section>
      {/* <!--------- Modern work section End ---------></div> */}
    </>
  );
};

export default FilesConvertInformativeComponent;
