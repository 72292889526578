import React from 'react';
import './sessionExpiredModal.scss';
import { AiOutlineExclamation } from 'react-icons/ai';
import { Cookies } from 'react-cookie';
import { Modal } from 'antd';
const SessionExpiredModal = () => {
  const cookies = new Cookies();
  const handleCancel = () => {
    cookies.remove('token', { path: '/' });
    cookies.remove('userEmail', { path: '/' });
    cookies.remove('userName', { path: '/' });
    cookies.remove('role', { path: '/' });
    window.location.reload();
    Modal.destroyAll();
    window.location.href = '/';
  };
  return (
    <div className='session-Model'>
      <div className='session-modal-logo'>
        <div className='bd-logo'>
          <AiOutlineExclamation />
        </div>
      </div>
      <div className='session-modal-text'>
        <>
          <h3>Session expired</h3>
          <p>Your current session has expired, and you have been logged out. Please log in again to continue.</p>
        </>
      </div>
      <div className='session-modal-btn'>
        <button className='primary_btn btn hl_cta_wrap' onClick={handleCancel}>
          Go Back to Login
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
