import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import paymentIntentSlice from '../slice/paymentIntentSlice';
import createCustomerSubscriptionSlice from '../slice/createCustomerSubscriptionSlice';
import getPriceSlice from '../slice/getPriceSlice';
import getAuthenticateSlice from '../slice/getAuthenticateSlice';

const store = configureStore({
  reducer: {
    paymentIntent: paymentIntentSlice,
    createCustomerSubscription: createCustomerSubscriptionSlice,
    getPriceData: getPriceSlice,
    authenticate: getAuthenticateSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store;
