import { Spin, Modal } from 'antd';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';
import { createCustomerSubscription } from '../../../redux/slice/createCustomerSubscriptionSlice';
import { getPrice } from '../../../redux/slice/getPriceSlice';
import Notify from '../../common/notify';
import { country, currency, paymentElementOptions, validEmail } from '../../../utils/commonUtils';
import './paymentModal.scss';
import { checkEmailExists } from '../../../redux/slice/checkEmailExistsSlice';

const PaymentModal = ({
  customer,
  createCustomerSubscription,
  checkoutModalOpen,
  setCheckoutModalOpen,
  getPrice,
  checkEmailExists
}) => {
  const [message, setMessage] = useState('');
  const [emailerror, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const cookies = new Cookies();
  const lang = cookies.get('lang');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchPriceData = async () => {
      const result = await getPrice(currency);
      const { type, payload, error } = result || {};
      if (type === 'getPrice/fulfilled') {
        setPriceData(payload.data);
      } else {
        Notify('error', error.message, '');
      }
    };

    fetchPriceData();
  }, [getPrice]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('Please enter email');
      return;
    } else if (!validEmail.test(email)) {
      setEmailError('Please enter valid email');
      return;
    }

    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const response = await checkEmailExists(email.toLowerCase());
      if (response?.type === 'checkEmailExists/fulfilled') {
        const { payload } = response || {};
        const { data, message } = payload || {};
        const { exists } = data;
        if (exists) {
          setMessage(message);
        } else {
          const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
          });
          if (result.error) {
            setMessage(result.error.message);
          } else {
            const apiPayload = {
              email: email.toLowerCase(),
              paymentMethodId: result?.paymentIntent?.payment_method,
              customerId: customer,
              priceId: priceData?.priceId,
              country,
              currency,
              language: lang
            };
            const subscriptionResult = await createCustomerSubscription(apiPayload);
            if (subscriptionResult?.type === 'createCustomerSubscription/fulfilled') {
              const { payload } = subscriptionResult || {};

              Notify('success', payload.message, '');
              setCheckoutModalOpen(false);
            } else {
              Notify('error', subscriptionResult.error.message, '');
            }
          }
        }
      } else {
        setMessage(response?.error?.message);
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      open={checkoutModalOpen}
      onCancel={() => setCheckoutModalOpen(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable
      maskClosable={false}
      className='cardpayment__wrap'
    >
      <Spin spinning={isLoading}>
        <div class='modal_number_info'>
          <div class='login-modal-title'>
            <p>Your file is ready for download</p>
          </div>
        </div>
        <div className='login-modal-section'>
          <div className='wrap-top-modal'>
            <div className='body-modal'>
              <div className='payment-details payment_modal_area'>
                <div className='df-details'>
                  <div className='left-payment'>
                    <p>Payment</p>
                  </div>
                  <div className='right-payment pay'>
                    <p>₹49.99</p>
                  </div>
                </div>
                <div class='right-payment pay'>
                  <span>24h trial, then ₹2495/month after trial</span>
                </div>
              </div>
              <form id='payment-form' onSubmit={handleSubmit}>
                <PaymentElement id='payment-element' options={paymentElementOptions} />
                <div className='payment__modal_input'>
                  <label htmlFor=''>Email Address</label>
                  <input
                    className='email_input'
                    placeholder='hello@mail.com'
                    value={email}
                    onChange={handleChangeEmail}
                  />
                  {emailerror && <p className='error-message'>{emailerror}</p>}
                </div>
                <button
                  id='submit'
                  className='hl_cta_wrap mt-4'
                  type='submit'
                  style={{ width: '100%' }}
                  disabled={isLoading}
                >
                  <span id='button-text'>Pay and Download</span>
                </button>
                <span id='sign-up-term'>
                  You will be charged $0.50 for a 1-day trial. At the end of 24 hours, the offer will be automatically
                  renewed in a subscription without commitment, at the price of $39 per month, unless canceled within
                  the trial period. Cancel anytime online or by email. 30-day money back guarantee, no questions asked.
                  Our goal is customer satisfaction.
                </span>
              </form>
              {message && <p className='error-message'>{message}</p>}
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCustomerSubscription: (data) => dispatch(createCustomerSubscription(data)),
  getPrice: (data) => dispatch(getPrice(data)),
  checkEmailExists: (data) => dispatch(checkEmailExists(data))
});

export default connect(null, mapDispatchToProps)(PaymentModal);
