import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../utils/axios';

export const createCustomerSubscription = createAsyncThunk('createCustomerSubscription', async (data) => {
  try {
    const response = await AxiosInstance.post(`/subscription/create-customer-subscription`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const createCustomerSubscriptionSlice = createSlice({
  name: 'createCustomerSubscription',
  initialState: {
    isLoading: false,
    createCustomerSubscriptionData: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomerSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createCustomerSubscriptionData = action.payload;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createCustomerSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export default createCustomerSubscriptionSlice.reducer;
