import axios from 'axios';
import { Modal } from 'antd';
import SessionExpiredModal from '../components/modals/sessionExpired/sessionExpiredModal';
import { API_KEY } from '../environment';
import { Cookies } from 'react-cookie';

const AxiosInstance = axios.create();

let isModalOpen = false;
const cookies = new Cookies();

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = cookies.get('token');
    config.baseURL = API_KEY;
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      config.headers['x-site'] = 'https://files-convert-git-stage-small-saas.vercel.app';
    }
    config.headers['Authorization'] = `Bearer ${token}`;

    // config.headers["x-language"] = cookies.get("lang") || "en";
    return config;
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && !isModalOpen) {
      isModalOpen = true;
      Modal.error({
        content: <SessionExpiredModal />,
        centered: true,
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { display: 'none' } },
        closable: false,
        className: 'session_expired_modal',
        onOk: () => {
          isModalOpen = false;
        },
        onCancel: () => {
          isModalOpen = false;
        }
      });
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
